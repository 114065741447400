import React, { useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import Record from "assets/images/mic.png";
import StopRecord from "assets/images/stop_green.png";
import { recordingDiv, prevAndNextButton } from "components/common/Styles";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { Bars } from "react-loader-spinner";
import { COLORS } from "constants";
import { useStateUpdate } from "UseStateUpdate";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { RECORDING_FORMAT } from "constants/otherConstant";

let recordTimer;
let seconds = 1;

function RecordDiv(props) {
  const {
    isRecording,
    stopRecordingMp3,
    startRecordingMp3,
    previousPage,
    nextPage,
    selectedIndex,
    selectedParagraphIndex,
    pages,
    pageContents,
    isAudioUploading,
    keyPressed,
    isPlaying,
    pauseAudio,
    playAudio,
    isRecordingEnabled,
    startRecordingWebm,
    handleStopRecordingWebm,
    openWaveEditModal,
    isEditing,
  } = props;
  const classes = recordingDiv();
  const timer = useStateUpdate("00:00:00");

  useEffect(() => {
    return () => {
      if (isRecording.state) {
        RECORDING_FORMAT === "webm"
          ? handleStopRecordingWebm()
          : stopRecordingMp3();
        stopTimer();
      }
    };
  }, []);

  useEffect(() => {
    switch (keyPressed.state) {
      case 32:
        if (isRecording.state) {
          RECORDING_FORMAT === "webm"
            ? handleStopRecordingWebm()
            : stopRecordingMp3();
          stopTimer();
        } else if (isRecordingEnabled.state) {
          RECORDING_FORMAT === "webm"
            ? startRecordingWebm()
            : startRecordingMp3();

          recordTimer = setInterval(() => startTimer(), 1000);
        }
        break;
      case 37:
        previousPage();
        break;
      case 39:
        nextPage();
        break;
      case 40:
        if (!(isAudioUploading.state || isRecording.state)) {
          isPlaying.state ? pauseAudio() : playAudio();
        }
        break;
      default:
        break;
    }
    keyPressed.update(null);
  }, [keyPressed.state]);

  const startTimer = () => {
    if ((selectedIndex.state === 1 && seconds >= 10) || seconds >= 181) {
      RECORDING_FORMAT === "webm"
        ? handleStopRecordingWebm()
        : stopRecordingMp3();

      stopTimer();
    } else {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;

      const formattedTime = [
        hours.toString().padStart(2, "0"),
        minutes.toString().padStart(2, "0"),
        remainingSeconds.toString().padStart(2, "0"),
      ].join(":");

      timer.update(formattedTime);
      seconds = seconds + 1;
    }
  };

  const stopTimer = () => {
    clearInterval(recordTimer);
    seconds = 1;
    timer.update("00:00:00");
  };

  const renderStopButton = () => {
    return (
      <Stack justifyContent="center" alignItems="center">
        <Typography variant="font16b" color="primary.main">
          {timer.state}
        </Typography>

        <IconButton
          className={classes.stopRecordButton}
          size="large"
          onClick={(e) => {
            RECORDING_FORMAT === "webm"
              ? handleStopRecordingWebm()
              : stopRecordingMp3();
            stopTimer();
          }}
        >
          <img src={StopRecord} alt="Stop Record" />
        </IconButton>
      </Stack>
    );
  };

  const renderStartButton = () => {
    return (
      <IconButton
        disabled={pageContents.state.length === 0}
        className={classes.recordButton}
        size="large"
        onClick={(e) => {
          RECORDING_FORMAT === "webm"
            ? startRecordingWebm()
            : startRecordingMp3();

          recordTimer = setInterval(() => startTimer(), 1000);
        }}
      >
        <img src={Record} width="70px" alt="Record" />
      </IconButton>
    );
  };

  const renderUnlockButton = () => {
    return (
      <Tooltip
        title={
          <Stack>
            <Typography variant="font15" textAlign="center">
              Logged in as an admin.
            </Typography>
            <Typography variant="font15" textAlign="center">
              Click to unlock recording.
            </Typography>
          </Stack>
        }
        placement="top"
      >
        <IconButton
          disabled={pageContents.state.length === 0}
          className={classes.recordButton}
          size="large"
          onClick={(e) => {
            isRecordingEnabled.update(true);
          }}
        >
          <LockOutlinedIcon style={{ fontSize: "50px" }} />
        </IconButton>
      </Tooltip>
    );
  };

  const renderButtonComponent = () => {
    let buttonComponent;

    if (isAudioUploading.state) {
      buttonComponent = (
        <IconButton disabled className={classes.recordButton} size="large">
          <Bars height="60" width="60" color={COLORS.primary} visible={true} />
        </IconButton>
      );
    } else if (!isRecordingEnabled.state) {
      buttonComponent = renderUnlockButton();
    } else if (isRecording.state) {
      buttonComponent = renderStopButton();
    } else {
      buttonComponent = renderStartButton();
    }

    return buttonComponent;
  };

  return (
    <Box
      sx={{
        pt: "20px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={4}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          {isRecording.state ? null : (
            <IconButton
              disabled={
                (selectedIndex.state === 1 &&
                  selectedParagraphIndex.state === 1) ||
                isAudioUploading.state
              }
              sx={prevAndNextButton}
              size="large"
              onClick={(e) => {
                previousPage();
              }}
            >
              <KeyboardDoubleArrowLeftIcon />
              <Typography variant="font16b">PREV</Typography>
            </IconButton>
          )}
        </Grid>
        <Grid item xs={4} container justifyContent="center">
          {renderButtonComponent()}
        </Grid>
        <Grid
          item
          xs={4}
          container
          justifyContent="flex-start"
          alignItems="center"
        >
          {isRecording.state ? null : (
            <IconButton
              disabled={
                (selectedIndex.state === pages.state.length &&
                  selectedParagraphIndex.state === pageContents.state.length) ||
                isAudioUploading.state
              }
              sx={prevAndNextButton}
              size="large"
              onClick={(e) => {
                console.log("hello");
                nextPage();
              }}
            >
              <Typography variant="font16b">NEXT</Typography>
              <KeyboardDoubleArrowRightIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default RecordDiv;
