import React, { useEffect, useRef } from "react";
import {
  Box,
  Fab,
  FormControlLabel,
  Grid,
  Skeleton,
  Stack,
  Switch,
  Typography,
  Tooltip,
} from "@mui/material";

import { useStateUpdate } from "UseStateUpdate";
import {
  WaveEditModal,
  WarningModal,
  CreateThreadModal,
  ConfirmModal,
  NoisyModal,
  PageViewModal,
} from "../modals";
import LeftScrollablePageView from "./sections/LeftScrollablePageView";
import RightScrollableParagraphView from "./sections/RightScrollableParagraphView";
import ContentView from "./sections/ContentView";
import RecordPageFooter from "./sections/RecordPageFooter";
import RecordDiv from "./sections/RecordDiv";
import ZoomInAndOut from "./sections/ZoomInAndOut";
import { getAudioBufferFromBlob, getContext } from "./sections/utils";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { scrollableSide, fabStyles } from "components/common/Styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getBookDetails, updateBookStatus } from "service/BookService";
import {
  TEXT_TO_RECORD_NORMAL_FONT_SIZE,
  PAGE_VIEW_NORMAL_SIZE,
  EXTRA_PAGES_INDEX as ExtraPagesIndex,
  MOBILE_MAX_WIDTH,
  BIT_RATE as bitRate,
} from "constants/otherConstant";
import { BOOK_STATUS_CODES as BookStatusCodes } from "constants";
import { checkBookStatus } from "utils/Checkbookstatus";
import RecordGuideLine from "./sections/RecordGuideLine";
import {
  createParagraph,
  deleteParagraph,
  pageDetails,
  updatePageStatus,
  uploadAudio,
} from "service/PageService";
import { useIsAuthor } from "utils/GetUserDetail";
import { COLORS } from "constants";
import { getAudioLength } from "utils/AudioLength";
import { updateParagraph } from "service/PageService";
import { getUserDetail } from "service/UserService";
import { getAudioUrl } from "utils/GetUrl";
import { RECORDING_FORMAT } from "constants/otherConstant";

const adminRoleId = process.env.REACT_APP_ENV === "dev" ? 1 : 2;

const isMobile = window.innerWidth <= MOBILE_MAX_WIDTH;

const MicRecorder = require("mic-recorder-to-mp3");

const recorder = new MicRecorder({
  bitRate: bitRate,
});

let sound;

let mediaRecorder = null;
let audioBlobs = [];
let capturedStream = null;

function Voices({ isToolbarHidden }) {
  const navigate = useNavigate();
  const classes = scrollableSide();
  const classes2 = fabStyles();
  const search = useLocation().search;
  const isAuthor = useIsAuthor();
  const pageParam = useStateUpdate(
    parseInt(new URLSearchParams(search).get("page"))
  );
  const paragraphParam = useStateUpdate(
    parseInt(new URLSearchParams(search).get("paragraph"))
  );

  const { bookId } = useParams();

  const openWaveEditModal = useStateUpdate(false);
  const tabValue = useStateUpdate(0);
  const isRecording = useStateUpdate(false);
  const showNoisyModal = useStateUpdate(false);
  const noisyModalMessage = useStateUpdate("");
  const noisyModalType = useStateUpdate(null);
  const regionStart = useStateUpdate(0);
  const regionEnd = useStateUpdate(1);
  const silenceCount = useStateUpdate(0);
  const waveModal = useStateUpdate(true);
  const selectedParagraph = useStateUpdate(null);
  const processingWave = useStateUpdate(false);
  const waveLoading = useStateUpdate(true);
  const clippingWave = useStateUpdate(false);
  const checkNewSilence = useStateUpdate(true);
  const silenceSeconds = useStateUpdate(0);
  const wavePlaying = useStateUpdate(false);
  const silencedWave = useStateUpdate(null);
  const editedWaves = useStateUpdate([]);
  const waveEdited = useStateUpdate(false);
  const disableClip = useStateUpdate(false);
  const audioLoaded = useStateUpdate(false);
  const waveBuffer = useStateUpdate(null);
  const context = useStateUpdate(null);
  const isPlaying = useStateUpdate(false);
  const isHideLeft = useStateUpdate(false);
  const keyPressed = useStateUpdate(null);
  const isEditing = useStateUpdate(false);

  const openPageViewModal = useStateUpdate(false);
  const openGuideLineModal = useStateUpdate(false);
  const openConfirmModal = useStateUpdate(false);
  const openWarningModal = useStateUpdate(false);
  const confirmModalData = useStateUpdate({
    icon: null,
    title: null,
    message: null,
    confirmButton: null,
    cancelButton: null,
    onConfirm: null,
    onCancel: null,
  });
  const warningModalMessage = useStateUpdate("");
  const pages = useStateUpdate(null);
  const isRefreshing = useStateUpdate(true);
  const isFetching = useStateUpdate(true);
  const isFetchingPageContent = useStateUpdate(true);
  const pageUrl = useStateUpdate("");
  const pageContents = useStateUpdate([]);
  const isParagraphActionsEnabled = useStateUpdate(false);
  const selectedIndex = useStateUpdate(0);
  const selectedParagraphIndex = useStateUpdate(0);
  const totalPages = useStateUpdate(0);
  const bookDetails = useStateUpdate(null);
  const navigateTo = useStateUpdate(null);
  const textToRecord = useStateUpdate("");
  const textToRecordFontSize = useStateUpdate(TEXT_TO_RECORD_NORMAL_FONT_SIZE);
  const pageViewSize = useStateUpdate(PAGE_VIEW_NORMAL_SIZE);
  const isAddingParagraph = useStateUpdate(false);
  const isRecordingCompleted = useStateUpdate(false);
  const isAudioUploading = useStateUpdate(false);
  const isDeleting = useStateUpdate(false);
  const openCreateThreadModal = useStateUpdate(false);

  const threadTitle = useStateUpdate("");
  const description = useStateUpdate("");
  const message = useStateUpdate("");
  const threadPage = useStateUpdate(null);
  const threadParagraph = useStateUpdate(null);
  const recordedUserId = useStateUpdate(null);

  const isRecordingEnabled = useStateUpdate(false);

  const showPagesType = useStateUpdate(false);

  const openWaveEditModalRef = useRef(openWaveEditModal.state);
  const isAddingParagraphRef = useRef(isAddingParagraph.state);
  const openPageViewModalRef = useRef(openPageViewModal.state);
  const openConfirmModalRef = useRef(openConfirmModal.state);
  const openWarningModalRef = useRef(openWarningModal.state);
  const showNoisyModalRef = useRef(showNoisyModal.state);
  const openCreateThreadModalRef = useRef(openCreateThreadModal.state);
  const openGuideLineModalRef = useRef(openGuideLineModal.state);
  const isEditingRef = useRef(isEditing.state);

  useEffect(() => {
    // Sync refs with the current state values
    openWaveEditModalRef.current = openWaveEditModal.state;
    isAddingParagraphRef.current = isAddingParagraph.state;
    openPageViewModalRef.current = openPageViewModal.state;
    openConfirmModalRef.current = openConfirmModal.state;
    openWarningModalRef.current = openWarningModal.state;
    showNoisyModalRef.current = showNoisyModal.state;
    openCreateThreadModalRef.current = openCreateThreadModal.state;
    openGuideLineModalRef.current = openGuideLineModal.state;
    isEditingRef.current = isEditing.state;
  }, [
    openWaveEditModal.state,
    isAddingParagraph.state,
    openPageViewModal.state,
    openConfirmModal.state,
    openWarningModal.state,
    showNoisyModal.state,
    openCreateThreadModal.state,
    openGuideLineModal.state,
    isEditing.state,
  ]);

  useEffect(() => {
    if (isMobile) {
      openWarningModal.update(true);
      warningModalMessage.update(
        "You cannot use this page on this device. Please use a desktop instead."
      );
      navigateTo.update("/dashboard");
    } else {
      isAuthor && isRecordingEnabled.update(true);
      fetchBookDetails();
      context.update(getContext());
    }

    window.addEventListener("keydown", handleKeyPress);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (bookDetails.state) {
      const bookStatus = checkBookStatus(
        BookStatusCodes.bookRecording,
        bookDetails.state,
        bookDetails.state.pages.length,
        "Currently, this book is not in the recording stage.",
        isAuthor
      );
      openWarningModal.update(bookStatus.isOpenModal);
      warningModalMessage.update(bookStatus.warningMessage);
      navigateTo.update(bookStatus.navigatePath);
      if (!bookStatus.isOpenModal) {
        pages.update(bookDetails.state.pages);
        totalPages.update(bookDetails.state.pages.length);
      }
    }
  }, [bookDetails.state]);

  const checkIsRecordedUserIsAdmin = async () => {
    const result = await getUserDetail(recordedUserId.state);
    if (result.status === 200) {
      const { data } = result.data;
      if (data.roleId === adminRoleId) {
        recordedUserId.update(null);
      }
    }
  };

  useEffect(() => {
    if (recordedUserId.state) {
      checkIsRecordedUserIsAdmin();
    }
  }, [recordedUserId.state]);

  useEffect(() => {
    if (selectedParagraphIndex.state) {
      const index = selectedParagraphIndex.state - 1;
      recordedUserId.update(pageContents.state[index]?.recorderUserId);
      if (pageContents.state.length > 0) {
        textToRecord.update(pageContents.state[index].paragraphText);
        let selected = {
          paragraphId: pageContents.state[index].paragraphId,
          orderIndex: pageContents.state[index].orderIndex,
          paragraphText: pageContents.state[index].paragraphText,
          audioUrl: pageContents.state[index].audioUrl,
          isRecorded: pageContents.state[index].isRecorded,
          aiAudioOptimization: pageContents.state[index].aiAudioOptimization,
        };
        pauseAudio();
        selectedParagraph.update(selected);
        prepareAudio(selected);
        if (
          paragraphParam.state &&
          paragraphParam.state > 0 &&
          paragraphParam.state <= pageContents.state.length
        ) {
          selectedParagraphIndex.update(paragraphParam.state);
          paragraphParam.update(0);
        }
      } else {
        textToRecord.update("");
      }
    }
  }, [selectedParagraphIndex.state, pageContents.state]);

  useEffect(() => {
    if (pages.state) {
      !selectedIndex.state && selectedIndex.update(1);
      const recordingCompleted = pages.state.every((page) => page.isRecorded);
      isRecordingCompleted.update(recordingCompleted);
      showPagesType.update(false);
    }
  }, [pages.state]);

  useEffect(() => {
    if (
      selectedParagraph.state &&
      pageParam.state &&
      pageParam.state > 0 &&
      pageParam.state <= pages.state.length
    ) {
      selectedIndex.update(pageParam.state);
      pageParam.update(0);
    }
  }, [selectedParagraph.state]);

  useEffect(() => {
    textToRecord.update("");
    isFetchingPageContent.update(true);
    if (selectedIndex.state) {
      if (
        showPagesType.state &&
        pages.state[selectedIndex.state - 1].isRecorded
      ) {
        let nextIndex = selectedIndex.state;
        while (
          nextIndex <= pages.state.length &&
          pages.state[nextIndex - 1].isRecorded
        ) {
          nextIndex++;
        }
        if (nextIndex <= pages.state.length) {
          selectedIndex.update(nextIndex);
        } else {
          selectedIndex.update(1);
        }
      } else {
        selectedParagraphIndex.update(1);
        isParagraphActionsEnabled.update(
          isActionsEnabled(selectedIndex.state - 1)
        );
        fetchPageDetails(pages.state[selectedIndex.state - 1].pageId);
        pauseAudio();
      }
    }
  }, [selectedIndex.state]);

  useEffect(() => {
    if (pageContents.state.length > 0 && !selectedParagraphIndex.state) {
      selectedParagraphIndex.update(1);
    }
    if (isDeleting.state) {
      checkIsRecorded();
      isDeleting.update(false);
    }
  }, [pageContents.state]);

  useEffect(() => {
    if (showPagesType.state) {
      let conditionMet = false;
      pages.state.forEach((page, index) => {
        if (!page.isRecorded && !conditionMet) {
          selectedIndex.update(index + 1);
          conditionMet = true;
        }
      });
    }
  }, [showPagesType.state]);

  const fetchBookDetails = async () => {
    const result = await getBookDetails(bookId);
    if (result.status === 200) {
      const { data } = result.data;
      bookDetails.update(data);
    }
  };

  const fetchPageDetails = async (pageId) => {
    isRefreshing.update(true);
    const result = await pageDetails(pageId);
    if (result.status === 200) {
      const { data } = result.data;
      pageContents.update(data);
      isRefreshing.update(false);
      isFetching.update(false);
      isFetchingPageContent.update(false);
    }
  };

  const setConfirmModalData = (
    icon,
    title,
    message,
    confirmButton,
    cancelButton,
    onConfirm,
    onCancel
  ) => {
    confirmModalData.update({
      icon,
      title,
      message,
      confirmButton,
      cancelButton,
      onConfirm,
      onCancel,
    });
    openConfirmModal.update(true);
  };

  const callDeletePara = async (paraId) => {
    isDeleting.update(true);
    const result = await deleteParagraph(
      pages.state[selectedIndex.state].pageId,
      paraId
    );
    if (result.status === 200) {
      fetchPageDetails(pages.state[selectedIndex.state - 1].pageId);
      openConfirmModal.update(false);
      selectedParagraphIndex.update(1);
    }
  };

  const callUpdateBookStatus = async () => {
    const body = { statusCode: BookStatusCodes.audioMerging };
    const result = await updateBookStatus(bookId, body);
    result.status === 200 &&
      navigate(
        isAuthor ? "/dashboard" : `/projects/${bookDetails.state.projectId}`
      );
  };

  const callUpdatePageStatus = async (pageId, isRecorded) => {
    if (!pages.state[selectedIndex.state - 1].isRecorded) {
      const body = { isRecorded: isRecorded, pageNumber: selectedIndex.state };
      const result = await updatePageStatus(pageId, body);
      result.status === 200 && fetchBookDetails();
    }
  };

  const callCreatePara = async (body) => {
    const result = await createParagraph(
      pages.state[selectedIndex.state - 1].pageId,
      body
    );
    if (result.status === 200) {
      fetchPageDetails(pages.state[selectedIndex.state - 1].pageId);
      openConfirmModal.update(false);
      if (pages.state[selectedIndex.state - 1].isRecorded) {
        callUpdatePageStatus(
          pages.state[selectedIndex.state - 1].pageId,
          false
        );
      }
    }
  };

  const callUpdateParagraph = async (paraId, body) => {
    const result = await updateParagraph(
      pages.state[selectedIndex.state].pageId,
      paraId,
      body
    );
    if (result.status === 200) {
      fetchPageDetails(pages.state[selectedIndex.state - 1].pageId);
      openConfirmModal.update(false);
    }
  };

  const isActionsEnabled = (index) => {
    return !(
      index === ExtraPagesIndex.sampleRecord ||
      index === ExtraPagesIndex.openingCredits ||
      index === pages.state.length - 1
    );
  };

  const startRecordingMp3 = (e) => {
    recorder
      .start()
      .then(() => {
        pauseAudio();
        isRecording.update(true);
      })
      .catch((e) => {
        console.error(`ERROR ${e}`);
      });
  };

  const stopRecordingMp3 = (e) => {
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        let audio_length = Math.round((blob.size * 8) / (320 * 1000));
        if (audio_length <= 1) {
          noisyModalMessage.update("Audio is too short.");
          noisyModalType.update("warning");
          showNoisyModal.update(true);
        } else if (audio_length >= 180) {
          noisyModalMessage.update(
            `The audio is too long; the maximum length is 3 minutes.`
          );
          noisyModalType.update("warning");
          showNoisyModal.update(true);
        } else if (selectedIndex.state === 1) {
          getRecordingBuffer(buffer, blob);
        } else {
          fileCreationUpload(buffer, blob);
        }
      })
      .catch((e) => {
        alert("We could not retrieve your message");
      });
    isRecording.update(false);
  };

  const getRecordingBuffer = async (buffer, blob) => {
    return getAudioBufferFromBlob(blob, context.state)
      .then((response) => {
        let channelData = response.getChannelData(0);
        let acceptResult = getDecibelValue(channelData, response.duration);
        showNoisyModal.update(true);
        acceptResult && fileCreationUpload(buffer, blob);
      })
      .catch((err) => console.log(err));
  };

  const fileCreationUpload = (buffer, blob) => {
    const fileName = `${pages.state[selectedIndex.state - 1].pageId}-${
      selectedParagraph.state.paragraphId
    }.${RECORDING_FORMAT}`;
    const file = new File(buffer, fileName, {
      type: blob.type,
      lastModified: Date.now(),
    });

    prepareAudio(selectedParagraph);
    onUpload(file, 0);

    // // Create a download link and trigger the download
    // const downloadLink = document.createElement("a");
    // downloadLink.href = URL.createObjectURL(file);
    // downloadLink.download = fileName;

    // // Append link to the body and trigger the download
    // document.body.appendChild(downloadLink);
    // downloadLink.click();

    // // Clean up the link
    // document.body.removeChild(downloadLink);
  };

  const checkIsRecorded = (buffer, blob) => {
    let isRecorded = true;
    pageContents.state.forEach((page) => {
      if (
        !page.isRecorded &&
        !(selectedParagraph.state.paragraphId === page.paragraphId)
      ) {
        isRecorded = false;
      }
    });
    if (isRecorded) {
      callUpdatePageStatus(
        pages.state[selectedIndex.state - 1].pageId,
        isRecorded
      );
    }
  };

  const getRmsValue = (channelData) => {
    let sum = 0;
    for (let i in channelData) {
      let square = channelData[i] * channelData[i];
      sum = sum + square;
    }
    let rms = Math.sqrt(sum / channelData.length);
    return rms;
  };

  const getDecibelValue = (channelData, duration) => {
    let message = "";
    let acceptResult = false;
    let rms = getRmsValue(channelData);
    let decibel = 20 * Math.log10(rms);
    if (decibel < -60) {
      message = "Silent audio, noise free environment.";
      noisyModalType.update("success");
      acceptResult = true;
    } else if ((decibel > -60) & (decibel < -35)) {
      message = "Silent audio, acceptable environment.";
      noisyModalType.update("success");
      acceptResult = true;
    } else if ((decibel > -35) & (decibel < -25)) {
      message =
        "Very noisy environment. Please check your surroundings and try again.";
      noisyModalType.update("error");
    } else {
      message = "Silence recording not accepted due to a rowdy environment.";
      noisyModalType.update("error");
    }
    noisyModalMessage.update(message);
    return acceptResult;
  };

  const onUpload = async (file, isOptimized) => {
    const pageId = pages.state[selectedIndex.state - 1].pageId;
    const paragraphId = selectedParagraph.state.paragraphId;
    isAudioUploading.update(true);

    const audioDuration = await getAudioLength(file)
      .then((duration) => {
        return duration;
      })
      .catch((error) => {
        console.error("Error getting audio duration:", error);
      });

    let formData = new FormData();
    formData.append("paragraphAudio", file);
    formData.append("paragraphNumber", selectedParagraphIndex.state);
    formData.append("pageNumber", selectedIndex.state);
    formData.append("audioDuration", audioDuration);
    let result = await uploadAudio(pageId, paragraphId, formData, isOptimized);
    if (result.status === 200) {
      fetchPageDetails(pageId);
      checkIsRecorded();
      isAudioUploading.update(false);
    }
  };

  const prepareAudio = (data) => {
    if (data.isRecorded) {
      let url = getAudioUrl(data.audioUrl) + "?t=" + new Date().getTime();
      sound = new Audio(url);
      sound.load();
      audioLoaded.update(true);
    } else {
      sound = null;
      audioLoaded.update(false);
      waveBuffer.update(null);
    }
  };

  const playAudio = (e) => {
    sound?.play();
    sound?.addEventListener("ended", () => stopPlaying());
    sound && isPlaying.update(true);
  };

  const pauseAudio = () => {
    sound?.pause();
    isPlaying.update(false);
  };

  const stopPlaying = () => {
    isPlaying.update(false);
  };

  const nextPage = () => {
    if (
      !(
        (selectedIndex.state === pages.state.length &&
          selectedParagraphIndex.state === pageContents.state.length) ||
        isAudioUploading.state ||
        isRecording.state
      )
    ) {
      pauseAudio();
      if (selectedParagraphIndex.state === pageContents.state.length) {
        let nextIndex = selectedIndex.state + 1;
        while (
          nextIndex <= pages.state.length &&
          showPagesType.state &&
          pages.state[nextIndex - 1].isRecorded
        ) {
          nextIndex++;
        }
        if (nextIndex <= pages.state.length) selectedIndex.update(nextIndex);
      } else {
        selectedParagraphIndex.update(selectedParagraphIndex.state + 1);
      }
    }
  };

  const previousPage = () => {
    if (
      !(
        (selectedIndex.state === 1 && selectedParagraphIndex.state === 1) ||
        isAudioUploading.state ||
        isRecording.state
      )
    ) {
      pauseAudio();
      if (selectedParagraphIndex.state === 1) {
        let prevIndex = selectedIndex.state - 1;
        while (
          prevIndex > 0 &&
          showPagesType.state &&
          pages.state[prevIndex - 1].isRecorded
        ) {
          prevIndex--;
        }
        if (prevIndex > 0) selectedIndex.update(prevIndex);
      } else {
        selectedParagraphIndex.update(selectedParagraphIndex.state - 1);
      }
    }
  };

  const handleChange = (event, newValue) => {
    tabValue.update(newValue);
  };

  const handleKeyPress = (event) => {
    const blockedStates = [
      openWaveEditModalRef.current,
      isAddingParagraphRef.current,
      openPageViewModalRef.current,
      openConfirmModalRef.current,
      openWarningModalRef.current,
      showNoisyModalRef.current,
      openCreateThreadModalRef.current,
      openGuideLineModalRef.current,
      isEditingRef.current,
    ];

    if (!blockedStates.some((state) => state)) {
      if (
        event.keyCode === 32 ||
        event.keyCode === 37 ||
        event.keyCode === 39 ||
        event.keyCode === 40
      ) {
        event.preventDefault();
        keyPressed.update(event.keyCode);
      }
    }
  };

  function startRecordingWebm() {
    pauseAudio();
    isRecording.update(true);
    return navigator.mediaDevices
      .getUserMedia({
        audio: {
          echoCancellation: true,
        },
      })
      .then((stream) => {
        audioBlobs = [];
        capturedStream = stream;

        // Use the extended MediaRecorder library
        mediaRecorder = new MediaRecorder(stream, {
          mimeType: `audio/${RECORDING_FORMAT}`,
        });

        // Add audio blobs while recording
        mediaRecorder.addEventListener("dataavailable", (event) => {
          audioBlobs.push(event.data);
        });

        mediaRecorder.start();
      })
      .catch((e) => {
        console.error(e);
      });
  }

  function stopRecordingWebm() {
    return new Promise((resolve) => {
      if (!mediaRecorder) {
        resolve(null);
        return;
      }

      mediaRecorder.addEventListener("stop", () => {
        const mimeType = mediaRecorder.mimeType;
        const audioBlob = new Blob(audioBlobs, { type: mimeType });

        if (capturedStream) {
          capturedStream.getTracks().forEach((track) => track.stop());
        }

        resolve(audioBlob);
      });

      mediaRecorder.stop();
    });
  }

  const handleStopRecordingWebm = async () => {
    let blob = await stopRecordingWebm();

    let audio_length = Math.round((blob.size * 8) / (320 * 1000));
    if (audio_length <= 1) {
      noisyModalMessage.update("Audio is too short.");
      noisyModalType.update("warning");
      showNoisyModal.update(true);
    } else if (audio_length >= 180) {
      noisyModalMessage.update(
        `The audio is too long; the maximum length is 3 minutes.`
      );
      noisyModalType.update("warning");
      showNoisyModal.update(true);
    } else if (selectedIndex.state === 1) {
      getRecordingBuffer([blob], blob);
    } else {
      fileCreationUpload([blob], blob);
    }
    isRecording.update(false);
  };

  return (
    <div tabIndex={0}>
      <div style={{ position: "relative" }}>
        <Tooltip
          placement="top"
          title={isHideLeft.state ? "Show Page View" : "Hide Page View"}
        >
          <Fab
            color="primary"
            size="small"
            className={classes2.fab}
            onClick={() => {
              isHideLeft.update(!isHideLeft.state);
            }}
          >
            {isHideLeft.state ? (
              <KeyboardDoubleArrowRightIcon />
            ) : (
              <KeyboardDoubleArrowLeftIcon />
            )}
          </Fab>
        </Tooltip>
      </div>
      <Box px={5}>
        <PageViewModal open={openPageViewModal} pageUrl={pageUrl.state} />
        <ConfirmModal {...{ openConfirmModal, confirmModalData }} />
        <WarningModal
          {...{ openWarningModal, warningModalMessage, navigateTo }}
        />
        <NoisyModal
          {...{ showNoisyModal, noisyModalMessage, noisyModalType }}
        />
        <RecordGuideLine {...{ openGuideLineModal }} />

        <CreateThreadModal
          open={openCreateThreadModal}
          userId={recordedUserId.state}
          {...{
            threadTitle,
            description,
            message,
            threadPage,
            threadParagraph,
            bookId,
          }}
        />

        <WaveEditModal
          open={openWaveEditModal}
          {...{
            regionStart,
            regionEnd,
            silenceCount,
            waveLoading,
            processingWave,
            clippingWave,
            wavePlaying,
            checkNewSilence,
            silenceSeconds,
            silencedWave,
            editedWaves,
            waveEdited,
            disableClip,
            selectedParagraph,
            context,
            waveModal,
            prepareAudio,
            onUpload,
            bookId,
            pages,
            selectedIndex,
          }}
        />
        {!isFetching.state && (
          <Grid container spacing={5}>
            {!isHideLeft.state && (
              <Grid container item xs={2} pb={10}>
                <Stack spacing={4}>
                  {!isRecordingCompleted.state && (
                    <Box style={{ border: "2px solid #ccc", borderRadius: 10 }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={showPagesType.state}
                            color="success"
                            onChange={(e) =>
                              showPagesType.update(e.target.checked)
                            }
                          />
                        }
                        label={
                          <Typography variant="font15b" color={COLORS.primary}>
                            View Unrecorded
                          </Typography>
                        }
                        labelPlacement="start"
                      />
                    </Box>
                  )}

                  <Grid
                    container
                    justifyContent="center"
                    className={classes.recording}
                    sx={{
                      maxHeight: isToolbarHidden.state ? "80vh" : "60vh",
                    }}
                  >
                    <LeftScrollablePageView
                      isDisabled={isRecording.state || isAudioUploading.state}
                      {...{
                        pages,
                        openPageViewModal,
                        pageUrl,
                        selectedIndex,
                        bookId,
                        showPagesType,
                      }}
                    />
                  </Grid>
                </Stack>
              </Grid>
            )}

            <Grid item xs={isHideLeft.state ? 10 : 8}>
              <Grid container spacing={1}>
                <Grid item xs={11}>
                  <Box>
                    <ContentView
                      {...{
                        bookDetails,
                        tabValue,
                        handleChange,
                        openWaveEditModal,
                        isPlaying,
                        pauseAudio,
                        playAudio,
                        textToRecord,
                        selectedIndex,
                        selectedParagraphIndex,
                        pages,
                        textToRecordFontSize,
                        pageViewSize,
                        pageContents,
                        bookId,
                        isRecording,
                        isAudioUploading,
                        isToolbarHidden,
                        fetchPageDetails,
                        isRefreshing,
                        selectedParagraph,
                        openCreateThreadModal,
                        threadTitle,
                        description,
                        threadPage,
                        threadParagraph,
                      }}
                    />
                    <RecordDiv
                      {...{
                        isRecording,
                        stopRecordingMp3,
                        startRecordingMp3,
                        previousPage,
                        nextPage,
                        selectedIndex,
                        selectedParagraphIndex,
                        pages,
                        pageContents,
                        isAudioUploading,
                        keyPressed,
                        isPlaying,
                        pauseAudio,
                        playAudio,
                        isRecordingEnabled,
                        startRecordingWebm,
                        handleStopRecordingWebm,
                        openWaveEditModal,
                        isEditing,
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <ZoomInAndOut
                    {...{ tabValue, textToRecordFontSize, pageViewSize }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={2}
              className={classes.recording}
              sx={{ maxHeight: isToolbarHidden.state ? "100vh" : "80vh" }}
              pb={10}
              pr={2}
            >
              {isFetchingPageContent.state ? (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="70vh"
                  style={{ borderRadius: 10 }}
                />
              ) : (
                <RightScrollableParagraphView
                  {...{
                    pageContents,
                    selectedParagraphIndex,
                    setConfirmModalData,
                    callDeletePara,
                    isParagraphActionsEnabled,
                    callCreatePara,
                    callUpdateParagraph,
                    openConfirmModal,
                    isAddingParagraph,
                    isEditing,
                  }}
                  isDisabled={isRecording.state || isAudioUploading.state}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Box>
      <RecordPageFooter
        {...{
          selectedIndex,
          totalPages,
          openGuideLineModal,
          isRecordingCompleted,
          callUpdateBookStatus,
          setConfirmModalData,
        }}
        isDisabled={isRecording.state || isAudioUploading.state}
      />
    </div>
  );
}

export default Voices;
