import React, { Fragment, useEffect } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import VerifyPageLeftScrollable from "./sections/VerifyPageLeftScrollable";
import VerifyPageRightScrollable from "./sections/VerifyPageRightScrollable";
import VerifyPageFooter from "./sections/VerifyPageFooter";
import { PageViewModal, ConfirmModal, WarningModal } from "components/modals";
import { useStateUpdate } from "UseStateUpdate";
import { scrollableSide } from "components/common/Styles";
import {
  getBookDetails,
  updateBookStatus,
  groupChapters,
} from "service/BookService";
import { useNavigate, useParams } from "react-router-dom";
import {
  deletePage,
  pageDetails,
  updateParagraph,
  deleteParagraph,
  mergeParagraph,
  createParagraph,
  mergeAllParagraph,
  updatePageStatus,
} from "service/PageService";
import { BOOK_STATUS_CODES as BookStatusCodes } from "constants";
import {
  EXTRA_PAGES_INDEX as ExtraPagesIndex,
  MOBILE_MAX_WIDTH,
} from "constants/otherConstant";
import { checkBookStatus } from "utils/Checkbookstatus";
import VerifyPageGuideLine from "./sections/VerifyPageGuideLine";

import AccessDenied from "components/common/AccessDenied";
import { useIsAuthorOrEngineer } from "utils/GetUserDetail";
import { COLORS } from "constants";
import { primaryButtonHover } from "components/common/Styles";
import { verifyAllPages } from "service/PageService";

const isMobile = window.innerWidth <= MOBILE_MAX_WIDTH;

function VerifyBook({ isToolbarHidden }) {
  const classes = scrollableSide();
  let navigate = useNavigate();
  const isAuthorOrEngineer = useIsAuthorOrEngineer();
  const { bookId } = useParams();
  const openPageViewModal = useStateUpdate(false);
  const openConfirmModal = useStateUpdate(false);
  const openWarningModal = useStateUpdate(false);
  const openGuideLineModal = useStateUpdate(false);
  const confirmModalData = useStateUpdate({
    icon: null,
    title: null,
    message: null,
    confirmButton: null,
    cancelButton: null,
    onConfirm: null,
    onCancel: null,
  });
  const warningModalMessage = useStateUpdate("");
  const pages = useStateUpdate(null);
  const isFetching = useStateUpdate(true);
  const pageUrl = useStateUpdate("");
  const pageContents = useStateUpdate([]);
  const isParagraphActionsEnabled = useStateUpdate(false);
  const selectedIndex = useStateUpdate(0);
  const totalPages = useStateUpdate(0);
  const bookDetails = useStateUpdate(null);
  const navigateTo = useStateUpdate(null);
  const mergeAllParaDisabled = useStateUpdate(true);
  const isVerifyCompleted = useStateUpdate(false);
  const onEditOrderIndex = useStateUpdate(null);
  const backDropOpen = useStateUpdate(false);

  const isFilterOnlyUnVerified = useStateUpdate(false);

  useEffect(() => {
    if (isMobile) {
      openWarningModal.update(true);
      warningModalMessage.update(
        "You cannot use this page on this device. Please use a desktop instead."
      );
      navigateTo.update("/dashboard");
    } else {
      !isAuthorOrEngineer && fetchBookDetails();
    }
  }, []);

  useEffect(() => {
    if (bookDetails.state) {
      const bookStatus = checkBookStatus(
        BookStatusCodes.bookUploaded,
        bookDetails.state,
        bookDetails.state.pages.length,
        "Currently, this book is not in the verifying stage."
      );
      openWarningModal.update(bookStatus.isOpenModal);
      warningModalMessage.update(bookStatus.warningMessage);
      navigateTo.update(bookStatus.navigatePath);
      if (!bookStatus.isOpenModal) {
        pages.update(bookDetails.state.pages);
        totalPages.update(bookDetails.state.pages.length - 1);
      }
    }
  }, [bookDetails.state]);

  useEffect(() => {
    if (pages.state) {
      !selectedIndex.state && selectedIndex.update(1);
      const verifyCompleted = pages.state
        .slice(1)
        .every((page) => page.isVerified);
      isVerifyCompleted.update(verifyCompleted);
    }
  }, [pages.state]);

  const isActionsEnabled = (index) => {
    return !(
      index === ExtraPagesIndex.sampleRecord ||
      index === ExtraPagesIndex.openingCredits
    );
  };

  useEffect(() => {
    if (selectedIndex.state) {
      isParagraphActionsEnabled.update(isActionsEnabled(selectedIndex.state));
      callFetchPageDetails();
    }
  }, [selectedIndex.state]);

  const fetchBookDetails = async () => {
    const result = await getBookDetails(bookId);
    if (result.status === 200) {
      const { data } = result.data;
      bookDetails.update(data);
    }
  };

  const callFetchPageDetails = () => {
    fetchPageDetails(
      pages.state[selectedIndex.state].pageId,
      pageContents,
      true
    );
  };

  const setConfirmModalData = (
    icon,
    title,
    message,
    confirmButton,
    cancelButton,
    onConfirm,
    onCancel
  ) => {
    confirmModalData.update({
      icon,
      title,
      message,
      confirmButton,
      cancelButton,
      onConfirm,
      onCancel,
    });
    openConfirmModal.update(true);
  };

  const fetchPageDetails = async (pageId, saveTo, isCurrent) => {
    const result = await pageDetails(pageId);
    if (result.status === 200) {
      const { data } = result.data;
      if (isCurrent) {
        saveTo.update(data);
        mergeAllParaDisabled.update(data.length === 1);
      } else {
        saveTo.update(data[0] ? data[0] : "");
      }
      isFetching.update(false);
    }
  };

  const callUpdatePageStatus = async (pageId, body) => {
    const result = await updatePageStatus(pageId, body);
    result.status === 200 && fetchBookDetails();
  };

  const callVerifyAllPages = async () => {
    const result = await verifyAllPages(bookId, {
      isVerified: true,
    });
    result.status === 200 && fetchBookDetails();
  };

  const callUpdateBookStatus = async () => {
    const body = { statusCode: BookStatusCodes.bookVerified };
    const result = await updateBookStatus(bookId, body);
    result.status === 200 && navigate(`/book-grouping/${bookId}`);
  };

  const callGroupChapters = async () => {
    const body = {};
    const result = await groupChapters(bookId, body);
    result.status === 200 && callUpdateBookStatus();
  };

  const callDeletePage = async (pageId, index) => {
    let result = await deletePage(pageId);
    if (result.status === 200) {
      openConfirmModal.update(false);
      fetchBookDetails();
      selectedIndex.update(index - 1);
    }
  };

  const callUpdateParagraph = async (paraId, body) => {
    const result = await updateParagraph(
      pages.state[selectedIndex.state].pageId,
      paraId,
      body
    );
    if (result.status === 200) {
      callFetchPageDetails();
      openConfirmModal.update(false);
    }
    onEditOrderIndex.update(null);
  };

  const callCreatePara = async (body) => {
    const result = await createParagraph(
      pages.state[selectedIndex.state].pageId,
      body
    );
    result.status === 200 && callFetchPageDetails();
    result.status === 200 && openConfirmModal.update(false);
    onEditOrderIndex.update(null);
  };

  const callDeletePara = async (paraId) => {
    const result = await deleteParagraph(
      pages.state[selectedIndex.state].pageId,
      paraId
    );
    result.status === 200 && callFetchPageDetails();
    result.status === 200 && openConfirmModal.update(false);
  };

  const callMergePara = async (
    currentParagraphId,
    mergeWithParagraphId,
    mergeDirection,
    lineBreak
  ) => {
    const body = {
      currentParagraphId,
      mergeWithParagraphId,
      mergeDirection,
    };
    const result = await mergeParagraph(
      pages.state[selectedIndex.state].pageId,
      body,
      lineBreak
    );

    if (result.status === 200) {
      callFetchPageDetails();
      openConfirmModal.update(false);
      backDropOpen.update(false);
    }
  };

  const callMergeAllPara = async () => {
    const result = await mergeAllParagraph(
      pages.state[selectedIndex.state].pageId
    );
    if (result.status === 200) {
      callFetchPageDetails();
      openConfirmModal.update(false);
      backDropOpen.update(false);
    }
  };

  return isAuthorOrEngineer ? (
    <AccessDenied />
  ) : (
    <Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backDropOpen.state}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box style={{ marginTop: "10px" }}>
        <Box px={15}>
          <PageViewModal open={openPageViewModal} pageUrl={pageUrl.state} />
          <ConfirmModal {...{ openConfirmModal, confirmModalData }} />
          <WarningModal
            {...{ openWarningModal, warningModalMessage, navigateTo }}
          />
          <VerifyPageGuideLine {...{ openGuideLineModal }} />

          <Grid container spacing={15}>
            <Grid item xs={4}>
              {!isFetching.state && (
                <Stack>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    p={1}
                    style={{ border: "2px solid #ccc", borderRadius: 10 }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isFilterOnlyUnVerified.state}
                          color="primary"
                          onChange={(e) =>
                            isFilterOnlyUnVerified.update(e.target.checked)
                          }
                        />
                      }
                      label={
                        <Typography variant="font15b" color={COLORS.primary}>
                          View Unverified
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <Button
                      disabled={isVerifyCompleted.state}
                      variant="outlined"
                      sx={primaryButtonHover}
                      size="small"
                      onClick={() => {
                        callVerifyAllPages();
                      }}
                    >
                      <Typography variant="font15b">Verify all</Typography>
                    </Button>
                  </Box>
                  <Grid
                    className={classes.verifying}
                    sx={{ maxHeight: isToolbarHidden.state ? "86vh" : "70vh" }}
                    py={1}
                    pl={5}
                    pr={2}
                  >
                    <VerifyPageLeftScrollable
                      {...{
                        openPageViewModal,
                        callUpdatePageStatus,
                        callDeletePage,
                        pages,
                        pageUrl,
                        selectedIndex,
                        setConfirmModalData,
                        bookId,
                        pageDetails,
                        isFilterOnlyUnVerified,
                      }}
                    />
                  </Grid>
                </Stack>
              )}
            </Grid>
            <Grid
              item
              xs={8}
              className={classes.verifying}
              sx={{ maxHeight: isToolbarHidden.state ? "90vh" : "77vh" }}
              p={1}
              pr={2}
            >
              {!isFetching.state && (
                <VerifyPageRightScrollable
                  {...{
                    openConfirmModal,
                    pageContents,
                    isParagraphActionsEnabled,
                    selectedIndex,
                    callUpdateParagraph,
                    callDeletePara,
                    callMergePara,
                    callCreatePara,
                    pages,
                    setConfirmModalData,
                    onEditOrderIndex,
                    pageDetails,
                    fetchPageDetails,
                    backDropOpen,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Box>
        {!isFetching.state && (
          <VerifyPageFooter
            {...{
              selectedIndex,
              totalPages,
              callMergeAllPara,
              mergeAllParaDisabled,
              setConfirmModalData,
              isVerifyCompleted,
              callGroupChapters,
              openGuideLineModal,
              backDropOpen,
            }}
          />
        )}
      </Box>
    </Fragment>
  );
}

export default VerifyBook;
