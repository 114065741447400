import React, { useEffect } from "react";
import { Alert, Box, Grid, Typography } from "@mui/material";
import { useStateUpdate } from "UseStateUpdate";
import BookCard from "./sections/BookCard";
import { COLORS } from "constants";
import { getAuthorDashboardData } from "service/DashboardService";
import { useIsAuthorOrEngineer } from "utils/GetUserDetail";
import { ThreeDots } from "react-loader-spinner";

function AuthorDashboard() {
  const books = useStateUpdate(null);
  const isAuthorOrEngineer = useIsAuthorOrEngineer();

  useEffect(() => {
    fetchAuthorDashBoardData();
  }, []);

  const fetchAuthorDashBoardData = async () => {
    const result = await getAuthorDashboardData();
    if (result.status === 200) {
      const { data } = result.data;
      books.update(data[0].Books);
    }
  };

  const statusCodeHeadings = {
    AUDIOOPTIMIZED: "Optimized Audio Books",
    BOOKRECORDING: "Books to Record",
  };

  const renderBooks = () => {
    // Group books by statusCode
    const groupedBooks = books.state.reduce((acc, book) => {
      const statusCode = book.status.statusCode;
      if (!acc[statusCode]) {
        acc[statusCode] = [];
      }
      acc[statusCode].push(book);
      return acc;
    }, {});

    // Render the grouped books
    if (Object.keys(groupedBooks).length === 0) {
      return (
        <Grid item xs={12} container justifyContent="center">
          <Alert severity="info">
            <Typography variant="body1">
              Your audiobook files are being processed. Once completed, your
              audiobook files will be forwarded to your publisher. <br />
              For questions related to your audiobook's availability on retail
              sites, contact your publisher.
            </Typography>
          </Alert>
        </Grid>
      );
    }

    return (
      <>
        {Object.keys(groupedBooks).map((statusCode) => (
          <React.Fragment key={statusCode}>
            <Typography variant="font20b" color={COLORS.primary} sx={{ mb: 2 }}>
              {statusCodeHeadings[statusCode] || statusCode}
            </Typography>
            <Grid container spacing={2} sx={{ mb: 6 }}>
              {groupedBooks[statusCode].map((book) => (
                // <Grid item xs={12} sm={6} md={4} key={book.bookId}>
                <BookCard
                  key={book.bookId}
                  title={book.bookName}
                  description={`Author: ${book.authorName}`}
                  bookStatus={book.status.statusCode}
                  {...{ book }}
                />
                // </Grid>
              ))}
            </Grid>
          </React.Fragment>
        ))}
      </>
    );
  };

  const renderLoading = () => {
    return (
      <Grid item xs={12} container justifyContent="center">
        <ThreeDots height="30" color={COLORS.primary} />
      </Grid>
    );
  };

  return (
    <Box
      mx={{ xs: 2, md: isAuthorOrEngineer ? 10 : 5 }}
      my={isAuthorOrEngineer && 5}
    >
      <Grid mb={2}>
        <Typography variant="font20b" color={COLORS.primary}>
          {/* Books */}
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        {books.state ? renderBooks() : renderLoading()}
      </Grid>
    </Box>
  );
}

export default AuthorDashboard;
