export const SUPPORT_MAIL = "support@myaudiobookrecorder.com";

export const DRAWER_WIDTH = 200;

export const BIT_RATE = 320;

export const UNEXPECTED_ERROR_MESSAGE = "An unexpected error occurred.";

export const TEXT_TO_RECORD_NORMAL_FONT_SIZE = 25;

export const TEXT_STEP_SIZE = 2;

export const PAGE_VIEW_NORMAL_SIZE = 100;

export const PAGE_STEP_SIZE = 10;

export const NOTIFICATION_MENU_WIDTH = 350;

export const MOBILE_MAX_WIDTH = 768;

export const EXTRA_PAGES_INDEX = {
  sampleRecord: 0,
  openingCredits: 1,
};

export const RECORDING_FORMAT = "webm";
