import React, { Fragment, useEffect } from "react";
import { Box, Grid, Chip, Typography, Stack, Tooltip } from "@mui/material";
import Correct from "assets/images/correct.png";
import CorrectGrey from "assets/images/correct_grey.png";
import Magnify from "assets/images/mg_white.png";
import { iconButtonImage, pageShowingCard } from "components/common/Styles";
import { COLORS } from "constants";
import { getImageUrl } from "utils/GetUrl";
import ImageComponent from "components/common/ImageComponent";

function LeftScrollablePageView(props) {
  const {
    pages,
    openPageViewModal,
    pageUrl,
    selectedIndex,
    isDisabled,
    showPagesType,
  } = props;
  const classes = iconButtonImage();
  const classes2 = pageShowingCard();

  useEffect(() => {
    scrollToDiv(selectedIndex.state);
  }, [selectedIndex.state]);

  const scrollToDiv = (id) => {
    const targetDiv = document.getElementById(id);
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const renderPage = (page, index) => {
    return (
      <Box
        key={page.pageId}
        id={index + 1}
        display="flex"
        justifyContent="space-between"
        sx={{
          // backgroundImage: `url("${getImageUrl(page.pageImageUrl)}")`,
          cursor: isDisabled && "no-drop",
          border:
            pages.state[selectedIndex.state - 1].pageId === page.pageId
              ? "2px solid #273891"
              : "1px solid #ccc",
          boxShadow:
            pages.state[selectedIndex.state - 1].pageId === page.pageId
              ? "0px 0px 10px rgba(39, 56, 145, 1)"
              : "0px 0px 10px rgba(0, 0, 0, 0.3)",
        }}
        style={{ position: "relative" }}
        className={classes2.recordingPageLeft}
        onClick={() => {
          !isDisabled && selectedIndex.update(index + 1);
        }}
      >
        <Grid container>
          <Grid item xs={6} p={0.5} style={{ position: "absolute" }}>
            <Chip label={index + 1} size="small" color="primary" />
          </Grid>
          <Grid
            item
            xs={6}
            container
            justifyContent="flex-end"
            p={0.5}
            style={{ position: "absolute", right: 0 }}
          >
            <img
              src={page.isRecorded ? Correct : CorrectGrey}
              className={classes.buttonImage}
              alt="Not recorded"
            />
          </Grid>

          <ImageComponent
            src={getImageUrl(page.pageImageUrl)}
            style={{
              width: "100%",
              height: `100%`,
              borderRadius: "10px",
            }}
            alt="page-image"
          />

          <Grid
            container
            alignItems="flex-end"
            style={{ position: "absolute", bottom: 0 }}
          >
            <Box
              width="100%"
              borderRadius=" 0 0 10px 10px "
              bgcolor={COLORS.gray}
              sx={{ cursor: isDisabled ? "no-drop" : "pointer" }}
              onClick={() => {
                if (!isDisabled) {
                  pageUrl.update(getImageUrl(page.pageImageUrl));
                  openPageViewModal.update(true);
                }
              }}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0.5}
              >
                <img
                  src={Magnify}
                  style={{ width: "12px", height: "12px" }}
                  alt="Not recorded"
                />
                <Typography variant="font12">View page</Typography>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Fragment>
      {pages.state.map((page, index) =>
        showPagesType.state
          ? !page.isRecorded && renderPage(page, index)
          : renderPage(page, index)
      )}
    </Fragment>
  );
}

export default LeftScrollablePageView;
