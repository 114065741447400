import React from "react";
import {
  Typography,
  Box,
  Grid,
  Tabs,
  Tab,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import EqualizerBtn from "assets/images/equalizer.png";
import PlayBtn from "assets/images/play-button.png";
import PauseBtn from "assets/images/pause-button.png";
import MessageIcon from "@mui/icons-material/Message";
import { contentView } from "components/common/Styles";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { ThreeDots } from "react-loader-spinner";
import { useIsAdmin } from "utils/GetUserDetail";
import {
  COLORS,
  AI_OPTIMIZATION_STATES as aiOptimizationStates,
} from "constants";
import { getImageUrl } from "utils/GetUrl";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RefreshButton = ({ fetchPageDetails, pages, selectedIndex }) => {
  return (
    <Tooltip title="Click to refresh">
      <IconButton
        onClick={(e) => {
          fetchPageDetails(pages.state[selectedIndex.state - 1].pageId);
        }}
      >
        <AutorenewIcon />
      </IconButton>
    </Tooltip>
  );
};

function ContentView(props) {
  const {
    bookDetails,
    tabValue,
    handleChange,
    openWaveEditModal,
    isPlaying,
    pauseAudio,
    playAudio,
    textToRecord,
    selectedIndex,
    selectedParagraphIndex,
    pages,
    textToRecordFontSize,
    pageViewSize,
    pageContents,
    isRecording,
    isAudioUploading,
    isToolbarHidden,
    fetchPageDetails,
    isRefreshing,
    selectedParagraph,
    openCreateThreadModal,
    threadTitle,
    description,
    threadPage,
    threadParagraph,
  } = props;
  const classes = contentView();
  const isAdmin = useIsAdmin();

  const isButtonDisable = () => {
    const isDisable =
      pageContents.state.length === 0 ||
      !selectedParagraph.state.isRecorded ||
      isRecording.state ||
      isAudioUploading.state ||
      isRefreshing.state;

    return isDisable;
  };

  const contentFooterMessage = () => {
    return !selectedParagraph.state.isRecorded ? (
      <Typography variant="font12" color={COLORS.primary}>
        No audio recorded.
      </Typography>
    ) : (
      selectedParagraph.state.aiAudioOptimization !==
        aiOptimizationStates.idle && (
        <Typography
          variant="font12"
          color={
            selectedParagraph.state.aiAudioOptimization ===
            aiOptimizationStates.running
              ? COLORS.primary
              : COLORS.green
          }
        >
          {selectedParagraph.state.aiAudioOptimization ===
          aiOptimizationStates.running
            ? `The audio has undergone AI optimization.`
            : `Audio is optimized by AI.`}
        </Typography>
      )
    );
  };

  const playOrPauseButton = () => {
    return (
      <Tooltip title={isPlaying.state ? "Pause" : "Play"}>
        <IconButton
          disabled={isButtonDisable()}
          color="primary"
          onClick={(e) => {
            isPlaying.state ? pauseAudio() : playAudio();
          }}
        >
          <img
            src={isPlaying.state ? PauseBtn : PlayBtn}
            className={classes.playAndEqualizerButton}
            alt={isPlaying.state ? "Pause" : "Play"}
          />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabValue.state} onChange={handleChange}>
          <Tab
            label="Text View"
            {...a11yProps(0)}
            sx={{
              backgroundColor: tabValue.state ? "#ccc" : "#fff",
              borderRadius: "10px 0 0 0",
            }}
          />
          {/* <Tab
            label="Page View"
            {...a11yProps(1)}
            sx={{
              backgroundColor: tabValue.state ? "#fff" : "#ccc",
              borderRadius: "0 10px 0 0",
            }}
          /> */}
        </Tabs>
      </Box>
      <TabPanel
        value={tabValue.state}
        index={0}
        className={classes.tabPanel}
        sx={{ maxHeight: isToolbarHidden.state ? "55vh" : "40vh" }}
      >
        <Grid container spacing={3}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            item
            xs={12}
            sx={{
              height: "6vh",
            }}
          >
            <Typography variant="font15b">
              {`Recording page ${selectedIndex.state}, Paragraph ${selectedParagraphIndex.state}`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              height: isToolbarHidden.state ? "50vh" : "35vh",
              overflow: "auto",
            }}
          >
            <Typography
              align="justify"
              variant={`font${textToRecordFontSize.state}`}
              style={{ whiteSpace: "pre-line" }}
            >
              {textToRecord.state}
            </Typography>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel
        value={tabValue.state}
        index={1}
        className={classes.tabPanel}
        sx={{ maxHeight: isToolbarHidden.state ? "55vh" : "40vh" }}
      >
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sx={{
              height: isToolbarHidden.state ? "50vh" : "35vh",
              overflow: "auto",
            }}
          >
            <img
              src={getImageUrl(
                pages.state[selectedIndex.state - 1].pageImageUrl
              )}
              alt="page view"
              loading="lazy"
              style={{ width: `${pageViewSize.state}%` }}
            />
          </Grid>
        </Grid>
      </TabPanel>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#ccc",
          borderRadius: "0 0 10px 10px",
          cursor: isButtonDisable() && "no-drop",
        }}
      >
        <Grid container p={1}>
          <Grid item xs={6}>
            <Stack direction="row" alignItems="center">
              {playOrPauseButton()}
              {isRefreshing.state
                ? selectedParagraph.state.aiAudioOptimization !==
                    aiOptimizationStates.idle && (
                    <ThreeDots height="10" color={COLORS.primary} />
                  )
                : contentFooterMessage()}
              {selectedParagraph.state.aiAudioOptimization ===
                aiOptimizationStates.running &&
                !isRefreshing.state && (
                  <RefreshButton
                    {...{ fetchPageDetails, pages, selectedIndex }}
                  />
                )}
            </Stack>
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            {isAdmin && (
              <Tooltip title="Create new thread">
                <IconButton
                  onClick={(e) => {
                    threadTitle.update(bookDetails.state.bookName);
                    description.update(
                      `Paragraph ${selectedParagraphIndex.state} of Page ${selectedIndex.state}`
                    );
                    threadPage.update(selectedIndex.state);
                    threadParagraph.update(selectedParagraphIndex.state);
                    openCreateThreadModal.update(true);
                  }}
                >
                  <MessageIcon style={{ color: "black" }} />
                </IconButton>
              </Tooltip>
            )}
            <Box
              style={{
                cursor:
                  (selectedIndex.state === 1 ||
                    isButtonDisable() ||
                    selectedParagraph.state.aiAudioOptimization ===
                      aiOptimizationStates.running) &&
                  "no-drop",
              }}
            >
              <Tooltip title="Edit">
                <IconButton
                  disabled={
                    selectedIndex.state === 1 ||
                    isButtonDisable() ||
                    selectedParagraph.state.aiAudioOptimization ===
                      aiOptimizationStates.running
                  }
                  color="#000"
                  onClick={(e) => {
                    pauseAudio();
                    openWaveEditModal.update(true);
                  }}
                >
                  <img
                    src={EqualizerBtn}
                    className={classes.playAndEqualizerButton}
                    alt="Equalizer"
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ContentView;
